
var quoteSubmit = document.getElementById("quoteSubmit");
var check1 = document.getElementById("check1");

quoteSubmit.addEventListener('click',function (e) {
    e.preventDefault();

    if( !check1.checked ) {
        check1.classList.add("form-check-input-error");

    } else {
        quoteSubmit.disabled = true;
        document.getElementById("quoteForm").submit();
    }



});
check1.addEventListener('click',function (e) {

        check1.classList.remove("form-check-input-error");

});